import React from 'react'
import CommonModal from '../../../../../components/modal/CommonModal'
import useShiftStore from '../../../../../app/stores/company/shiftStore'
import CommonViewComponent from '../../../../../components/viewer/CommonViewComponent';
import ImageViewer from '../../../../../components/image/ImageViewer';
import RequestDetailsTextTitle from '../../../../common/requests/requestDetails/components/mainContents/components/RequestDetailsTextTitle';
import { formatDate, formatDateTime, formatTime } from '../../../../../app/utility/utilityFunctions';
import { useTranslation } from 'react-i18next';
import { k_shift_stop_status } from '../../../../../app/utility/const';

const CustomStopDetails = () => {
    const { t } = useTranslation();
    const { showCustomStopModal, setShowCustomStopModal, customStopModalData } = useShiftStore();
    // const defineStatus = (status) => {
    //     if (status === k_shift_stop_status.init) return 'Not Started';
    //     if (status === k_shift_stop_status.hold) return 'On Hold';
    //     if (status === k_shift_stop_status.not_delivered) return 'Not Delivered';
    //     else return status;
    // }
    function defineStatus(status = "", approxTimeAvailable = false) {
        const statusMappings = {
            'complete': 'completed',
            'delivered': 'completed',
            'not_delivered': 'not completed',
            'hold': 'on hold',
            'init': 'not optimized',
            'ongoing': 'ongoing',
            'optimized': 'optimized',
        };

        // First mapping from the first function
        // if (status in statusMappings) {
        //     if (status === 'init' && approxTimeAvailable) status = 'optimized';
        //     else 
        // }
        if (!!status) status = statusMappings[status];
        else status = 'not started';
        // Logic from the second function

        // if (status !== 'delivered') {
        //     status = 'not completed';
        // }

        // Capitalize first letter
        return status?.charAt(0).toUpperCase() + status?.slice(1);
    }

    const isShowDriverComment = (status) => {
        if (status === k_shift_stop_status.delivered || status === k_shift_stop_status.complete || status === k_shift_stop_status.not_delivered)
            return true;
        else return false;
    }

    const getExpectedTime = () => {
        const date = customStopModalData?.approx_time?.split(" ")?.[0]
        const time = customStopModalData?.approx_time?.split(" ")?.[1]

        return formatDateTime(date, time, true)
    }

    const getCompletedTime = () => {
        const date = customStopModalData?.driver_complete_time?.split(" ")?.[0]
        const time = customStopModalData?.driver_complete_time?.split(" ")?.[1]

        return formatDateTime(date, time, true)
    }

    return (
        <CommonModal
            showModal={showCustomStopModal}
            setShowModal={setShowCustomStopModal}
            modalTitle={t('Custom Stop Details')}
            modalSpace={true}
            mainContent={
                <div
                    onClick={() => { console.log('customStopModalData: ', customStopModalData); }}
                    className='pt-5 space-y-5'>
                    <div className='flex w-full justify-between'>
                        <CommonViewComponent capitalizedData={true} labelText={t('Status')}
                            value={defineStatus(customStopModalData?.status, !!customStopModalData?.approx_time) ?? " "} />
                        {
                            (customStopModalData?.status !== "delivered" && customStopModalData?.status !== "not_delivered" && customStopModalData?.status === "optimized") || customStopModalData?.status === "ongoing"
                                ?
                                <p className='font-normal break-all text-fs14 min-h-4 text-cGray800'>{`Exp. Complete: ${getExpectedTime()}`}</p>
                                : 
                                customStopModalData?.status === "delivered" ? <p className='font-normal break-all text-fs14 min-h-4 text-cGray800'>{`Completed: ${getCompletedTime()}`}</p> 
                                : customStopModalData?.status === "not_delivered" ? <p className='font-normal break-all text-fs14 min-h-4 text-cGray800'>{`Not Completed: ${getCompletedTime()}`}</p> 
                                : <></>
                        }
                    </div>
                    <CommonViewComponent capitalizedData={true} labelText={t('Stop Type')} value={'Custom'} />
                    <CommonViewComponent capitalizedData={false} labelText={t('Title')} value={customStopModalData?.title ?? " "} />
                    <CommonViewComponent capitalizedData={false} labelText={t('Date')} value={(formatDate(customStopModalData?.date) ?? " ")} />
                    <CommonViewComponent capitalizedData={false} labelText={t('Time Range')} value={(formatTime(customStopModalData?.start_time) ?? " ") + ' - ' + formatTime(customStopModalData?.end_time) ?? "--"} />

                    <CommonViewComponent capitalizedData={false} labelText={t('Duration (min)')} value={customStopModalData?.duration ?? "No duration"} />
                    <CommonViewComponent capitalizedData={false} labelText={t('Address')} value={customStopModalData?.address ?? "No address"} />
                    <CommonViewComponent capitalizedData={false} labelText={t('Floor')} value={customStopModalData?.floor_number ?? "No floor number"} />

                    <CommonViewComponent capitalizedData={false} labelText={t('Comment')} value={customStopModalData?.comment ?? "No comment"} />
                    {customStopModalData?.attachment ?
                        <ImageViewer src={customStopModalData?.attachment} label={t('Attachment')} />
                        : ''
                    }

                    {(customStopModalData?.status === "delivered" || customStopModalData?.status === "not_delivered" || customStopModalData?.status === "complete")
                        ?
                        <>
                            <RequestDetailsTextTitle title={t(`Completion Details`)} className={'text-fs16 font-fw500'} />

                            {isShowDriverComment(customStopModalData?.status) && <CommonViewComponent
                                labelText={t('Driver Comment')}
                                value={customStopModalData?.driver_comment ?? 'No comment'}
                                className='my-[20px]'
                            />}

                            <div className='flex flex-row items-center justify-start space-x-5 my-s20'>

                                {customStopModalData?.driver_attachment && <ImageViewer src={customStopModalData?.driver_attachment} label={t('Attachment')} withExtension={true} />}
                                {customStopModalData?.driver_signature && <ImageViewer src={customStopModalData?.driver_signature} label={t('Signature')} is_signature={true} />}

                            </div>
                        </>
                        : <></>
                    }
                </div>
            }
        />
    )
}

export default CustomStopDetails