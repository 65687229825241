import React, { useState } from 'react'
import CommonModal from '../../../../components/modal/CommonModal'
import { useTranslation } from 'react-i18next'
import useUtilityStore from '../../../../app/stores/others/utilityStore';
import CommonInput from '../../../../components/input/CommonInput';
import CommonButton from '../../../../components/button/CommonButton';
import { requestShare } from '../../../../app/stores/others/requestStore';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

function CommonShareModal() {

    const { showCommonShareModal, setShowCommonShareModal } = useUtilityStore();

    const { t } = useTranslation();

    const [email, setEmail] = useState("");

    useEffect(() => {
        setEmail("")
    }, [showCommonShareModal]);

    const params = useParams();

    const { request_id } = params;

    return (
        <div>
            <CommonModal
                showModal={showCommonShareModal}
                setShowModal={setShowCommonShareModal}
                modalTitle={t("Share")}
                widthClass='w-[45vw]'
                modalSpace={true}
                mainContent={
                    <>
                        <form onSubmit={async (e) => {
                            e.preventDefault();
                            const data = {
                                req_id: request_id,
                                email: email
                            }
                            console.log("shareForm ::", data)
                            const success = await requestShare(data);
                            if (success) {
                                setShowCommonShareModal(false);
                                setEmail("")
                            }
                        }}>
                            <div className='space-y-5'>
                                <CommonInput
                                    show_asterisk={false}
                                    required={true}
                                    type='email'
                                    value={email}
                                    onChange={(e) => { setEmail(e.target.value) }}
                                    placeholder={t("Email address")}
                                    labelText={t("Email address")}
                                />

                                <div className='flex items-center justify-center'>
                                    <CommonButton
                                        type='submit'
                                        btnLabel={t("Share")}
                                    />
                                </div>
                            </div>

                        </form>
                    </>
                }
            />
        </div>
    )
}

export default CommonShareModal
