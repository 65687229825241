/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import CommonModal from '../../../../../../components/modal/CommonModal'
import CommonDatePicker from '../../../../../../components/input/CommonDatePicker'
import CommonSelect from '../../../../../../components/select/CommonSelect'
import FilterRange from '../../../../../../components/utility/FilterRange'
import CommonButton from '../../../../../../components/button/CommonButton'
import useRequestStore, { getTableViewRequestsData, onFilterValueChange, transportationTypeArray } from '../../../../../../app/stores/others/requestStore'
import { k_requests_status, user_role as role } from '../../../../../../app/utility/const'
import { getStringFromDateObject } from '../../../../../../app/utility/utilityFunctions'
import useGeneralStore from '../../../../../../app/stores/others/generalStore'
import { useTranslation } from 'react-i18next'
import { kuGetRequestsNew } from '../../../../../../app/urls/commonUrl'
import MultipleSelectCombo from '../../../../../../components/select/MultipleSelectCombo'
import CommonSingleSelect from '../../../../../../components/select/CommonSingleSelect'

export default function HistoryFilter({ showModal, setShowModal }) {
  const { setRequestApiUrl, request_filter_form, changeRequestFilterForm, transport_type, filter_range, resetRequestFilterForm, resetFilterRange, updateRequestFilterForm, request_filter_form_copy, updateRequestFilterFormCopy, awarded_company, customers } = useRequestStore();
  const { user_role } = useGeneralStore();

  const divRef = useRef(null);
  const { t } = useTranslation();


  const status = ([
    { title: t('Waiting'), id: 'waiting' },
    { title: t('Rated'), id: 'rated' },
    { title: t('Not Rated'), id: 'not_rated' }
  ]);

  useEffect(() => { updateRequestFilterForm(request_filter_form_copy) }, [showModal]);

  useEffect(() => { if (divRef.current) divRef.current.focus() }, []);

  return (
    <CommonModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalSpace={true}
      modalTitle={
        <div className='flex items-baseline'>
          <div>{t('Filter')}</div>
          <div className='pl-4 text-base cursor-pointer select-none drop-shadow-sm text-cRed'
            onClick={async () => {
              resetRequestFilterForm();
              resetFilterRange();
              setRequestApiUrl(kuGetRequestsNew);
              await getTableViewRequestsData({
                data_set: k_requests_status.history,
                filter: {
                  transport_types: [],
                  awarded_company: null,
                  customer_from: null,
                  status: "",
                  completed_to: "",
                  completed_from: "",
                  min_budget: "",
                  max_budget: "",
                },
                hasFilter: false
              });
              updateRequestFilterFormCopy("");
              setShowModal(false);
            }} >{t('Clear')}</div>
        </div>
      }
      mainContent={
        <>
          <form className='mt-6' onSubmit={async (e) => {
            e.preventDefault();
            setRequestApiUrl(kuGetRequestsNew);
            await getTableViewRequestsData({
              data_set: k_requests_status.history, filter: {
                transport_type: request_filter_form?.transport_types?.map(item => item?.id),
                awarded_company: user_role === role.customer ? request_filter_form?.awarded_company ? [request_filter_form?.awarded_company] : [] : [],
                customer_from: user_role === role.company ? request_filter_form?.customer_from ? [request_filter_form?.customer_from] : [] : [],
                status: request_filter_form?.status ? [request_filter_form?.status] : [],
                completed_to: request_filter_form?.completed_to,
                completed_from: request_filter_form?.completed_from,
                min_budget: request_filter_form?.min_budget,
                max_budget: request_filter_form?.max_budget,
              }
            });
            updateRequestFilterFormCopy(request_filter_form);
            setShowModal(false);
          }}>

            <div tabIndex="0" ref={divRef} className='pt-0' ></div>

            {user_role === role.customer ? <>
              <div className='mt-s26'>
              <MultipleSelectCombo
                label={t('Type')}
                dataArray={transportationTypeArray()}
                onChange={(value) => {
                  changeRequestFilterForm('transport_types', value);
                }}
                selectedId={request_filter_form?.transport_types}
                 />
              </div>

              <div className='mt-[26px] grid grid-cols-2 items-center gap-7'>
                <CommonSingleSelect
                  label={t('Select Company')} 
                  placeholder={t('Select Company')} 
                  dataArray={awarded_company?.length > 0 ? awarded_company : []} 
                  has_subtitle={false} 
                  onChange={(value) => {
                    changeRequestFilterForm('awarded_company', value);
                  }} 
                  value={request_filter_form?.awarded_company}
                />

                <CommonSingleSelect 
                  label={t('Select Status')} 
                  placeholder={t('Select Status')} 
                  dataArray={status} 
                  has_subtitle={false} 
                  onChange={(value) => {
                    changeRequestFilterForm('status', value);
                  }} 
                  value={request_filter_form?.status} 
                  isCapitalize={true}
                />
              </div>

              <div className="mt-[26px] grid grid-cols-2 items-center gap-7">
                <CommonDatePicker
                  value={request_filter_form?.completed_from}
                  onChange={(date) => {
                    if(!date) {
                      changeRequestFilterForm('completed_from', null);
                      return;
                    }
                    if (new Date(date) > new Date(request_filter_form?.completed_to)) {
                      changeRequestFilterForm('completed_from', getStringFromDateObject(date))
                      changeRequestFilterForm('completed_to', "")
                    } else {
                      changeRequestFilterForm('completed_from', getStringFromDateObject(date))
                    }
                  }}
                  label={t('Completed From')}
                  showClear={true}
                />

                <CommonDatePicker
                  startDate={request_filter_form?.completed_from}
                  value={request_filter_form?.completed_to}
                  onChange={(date) => {
                    if(!date) {
                      changeRequestFilterForm('completed_to', null);
                      return;
                    }
                    changeRequestFilterForm('completed_to', getStringFromDateObject(date))}
                  }
                  label={t('Completed To')}
                />
              </div>
            </> :
              <>
                <div className="mt-[26px] items-center gap-7">
                <MultipleSelectCombo 
                  label={t('Type')}
                  dataArray={transportationTypeArray()}
                  onChange={(value) => {
                    changeRequestFilterForm('transport_types', value);
                  }}
                  selectedId={request_filter_form?.transport_types}
                 />
                 </div>

                 <div className='mt-s26'>
                  <CommonSingleSelect 
                    label={t('Select Customer')} 
                    placeholder={t('Select Customer')} 
                    dataArray={customers?.length > 0 ? customers : []} 
                    has_subtitle={false} 
                    onChange={(value) => {
                      changeRequestFilterForm('customer_from', value);
                    }} 
                    value={request_filter_form?.customer_from}
                    />
                </div>



                <div className="mt-[26px] grid grid-cols-2 items-center gap-7">
                  <CommonDatePicker
                    value={request_filter_form?.completed_from}
                    onChange={(date) => {
                      if(!date) {
                        changeRequestFilterForm('completed_from', null);
                        return;
                      }
                      if (new Date(date) > new Date(request_filter_form?.completed_to)) {
                        changeRequestFilterForm('completed_from', getStringFromDateObject(date))
                        changeRequestFilterForm('completed_to', "")
                      } else {
                        changeRequestFilterForm('completed_from', getStringFromDateObject(date))
                      }
                    }}
                    label={t('Completed From')}
                    showClear={true}
                  />

                  <CommonDatePicker
                    startDate={request_filter_form?.completed_from}
                    value={request_filter_form?.completed_to}
                    onChange={(date) => {
                      if(!date) {
                        changeRequestFilterForm('completed_to', null);
                        return;
                      }
                      changeRequestFilterForm('completed_to', getStringFromDateObject(date))}
                    }
                    label={t('Completed To')}
                    showClear={true}
                  />
                </div>
              </>
            }

            <FilterRange
              className={'mt-s26'}
              value={filter_range?.value}
              defaultValue={filter_range?.defaultValue}
              min={filter_range?.min}
              max={filter_range?.max}
              onChange={onFilterValueChange}
            />

            <div className='flex flex-row items-center justify-end w-full mt-5'>
              <CommonButton btnLabel={t('Apply Filter')} type='submit' />
            </div>


          </form>
        </>
      }
    />
  )
}
