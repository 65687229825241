import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useGeneralStore, { getAllUserInfo } from '../../app/stores/others/generalStore';
import CommonModal from './CommonModal';
import Image from '../image/Image';
import { commonSelectedChat } from '../../app/stores/others/chatStore';
import { chat_role, user_role as role } from '../../app/utility/const';
import { iChatBlue } from '../../app/utility/imageImports';
import CommonDetailsItem from './CommonDetailsItem';

export default function CustomerDetailsModal() {

    const { showCustomerDetailsModal, setShowCustomerDetailsModal, customerDetails } = useGeneralStore();

    const { t } = useTranslation();

    const { user_role } = useGeneralStore();

    const navigateTo = useNavigate();

    return (
        <CommonModal
            showModal={showCustomerDetailsModal ? true : false}
            setShowModal={setShowCustomerDetailsModal}
            modalTitle={t("Customer Details")}
            modalSpace={true}
            mainContent={
                <>
                    {customerDetails?.email ? <div className=''>

                        <div className='flex flex-wrap md:flex-nowrap mt-s16'>
                            <div className='max-w-[160px] min-w-[160px]'>
                                <Image src={customerDetails?.image} roundedFull={true} className='w-[160px] h-[160px]' />
                            </div>

                            <div className="w-full mt-s20 md:mt-0 md:ml-s30">
                                <div>

                                    <div className='flex justify-between'>
                                        <div className='text-cMainBlack text-fs24 font-fw600 mb-s8'>{t("Profile Info")}</div>
                                        <div className='flex justify-center'
                                            onClick={async () => {
                                                const res = await getAllUserInfo(customerDetails?.id);
                                                if (res?.success) {
                                                    await commonSelectedChat(res?.data, user_role === role.customer ? chat_role.customer : chat_role.company);
                                                    setShowCustomerDetailsModal(false);
                                                    navigateTo("/chat");
                                                }
                                            }}>
                                            <div className='flex items-center space-x-1 cursor-pointer'>
                                                <img
                                                    src={iChatBlue} alt=''
                                                    className='cursor-pointer'
                                                />
                                                <div className='text-fs16 font-fw500 text-cMainBlue'>Chat</div>
                                            </div>
                                        </div>
                                    </div>

                                    <CommonDetailsItem
                                        title={t("Name")}
                                        value={customerDetails?.name ? customerDetails?.name : 'NA'}
                                    />

                                    <CommonDetailsItem
                                        title={t("Email")}
                                        value={customerDetails?.email ? customerDetails?.email : 'NA'}
                                    />

                                    <div className='text-cMainBlack text-fs14 mb-s2'>
                                        <span className='font-fw600'>{t("Address")}: </span>
                                        <span className="break-all font-fw500">
                                            {customerDetails?.address ? <a target={'_blank'} rel='noreferrer'
                                                href={`http://maps.google.com/?q=${customerDetails?.address}`}>{customerDetails?.address}</a>
                                                : "NA"
                                            }
                                        </span>
                                    </div>


                                    <CommonDetailsItem
                                        title={t("Phone")}
                                        value={customerDetails?.phone ? customerDetails?.phone : 'NA'}
                                    />


                                </div>
                            </div>
                        </div>
                    </div>
                        :
                        <div className='flex items-center justify-center h-[20vh] text-xl'>
                            {t("Select list item to view details.")}
                        </div>}
                </>
            }

        />
    )
}
