import React from 'react'
import CommonModal from '../../../../components/modal/CommonModal';
import useCreateRequestStore from '../../../../app/stores/others/createRequestStore';
import { useTranslation } from 'react-i18next';
import { iLink, iPast } from '../../../../app/utility/imageImports';
import { user_role as role, table_View_csv, table_View_csv_customer } from '../../../../app/utility/const';
import useGeneralStore from '../../../../app/stores/others/generalStore';

const TableViewInstructionsModal = () => {

    const { t } = useTranslation();

    const { showTableViewInstructionsModal, setTableViewInstructionsModal } = useCreateRequestStore();

    const { user_role } = useGeneralStore();

    return (
        <div>
            <CommonModal
                showModal={showTableViewInstructionsModal}
                setShowModal={setTableViewInstructionsModal}
                modalTitle={t("Instructions")}
                modalSpace={true}
                widthClass='min-w-[780px]'
                mainContent={
                    <>

                        <div className="mx-auto mt-5 space-y-4">
                            <ul className="pl-5 space-y-2 font-sans font-normal list-disc text-fs14 text-cGray800">
                                <li>{t("Open your XL or CSV file")}</li>
                                <li>{t("Select some specific column and Copy them.")}</li>
                                <li>
                                    <div className='flex space-x-1'>
                                        <span>{("Open the table view and click on the paste icon")}</span>
                                        <span className='flex space-x-1'>(<img src={iPast} alt="" />)</span>
                                        <span>{t("of the desired column.")}</span>
                                    </div>
                                </li>
                                <li>{t("For Comment, maximum character limit is 255.")}</li>

                                {
                                    user_role === role.customer ?
                                        <>
                                            <li>
                                                Add multiple products by adding comma (,) after each product. Example: <strong>Product 1</strong>, <strong>Product 2</strong>, <strong>Product 3</strong>
                                            </li>
                                        </>
                                        : <></>
                                }

                                <li>{t("Address format: ")}<strong className='font-semibold'>{t("Street name No, zip City, country")}</strong></li>
                                {
                                    user_role === role.company ?
                                        <>
                                            <li>{t("For Start time, you need to select the")} <strong className='font-semibold'>START TIME</strong>, {t("the system will add extra 2 hrs as range.")}</li>
                                            <li>
                                                Add multiple products by adding comma (,) after each product. Example: <strong>Product 1</strong>, <strong>Product 2</strong>, <strong>Product 3</strong>
                                            </li>
                                            <li>{("Date format")}: <strong className='font-semibold'>dd.mm.yyyy</strong> <span className='text-cGray400'>{t("or")}</span> <strong className='font-semibold'>dd/mm/yyyy</strong>  <span className='text-cGray400'>or</span> <strong className='font-semibold'>dd-mm-yyyy</strong></li>
                                            <li>{t("Start Time format")}: <strong className='font-semibold'>hh:mm</strong></li>
                                        </>
                                        :
                                        <></>
                                }

                            </ul>
                        </div>
                        <a href={user_role === role.company ? table_View_csv : table_View_csv_customer} target="_blank" rel="noopener noreferrer" className="flex items-center justify-start mt-5 space-x-2 outline-none cursor-pointer w-fit">
                            <div className="font-semibold text-fs14 text-cBrand">{t("See demo table")}</div>
                            <img className='max-w-[15px] min-w-[15px] min-h-[15px] max-h-[15px] ' alt="" src={iLink} />
                        </a>
                    </>
                }
            />
        </div>
    )
}

export default TableViewInstructionsModal