import React from 'react'
import CommonModal from '../../../../../../../../components/modal/CommonModal'
import CommonButton from '../../../../../../../../components/button/CommonButton'
import { useTranslation } from 'react-i18next';

export default function ConfirmBiddingRequestEditModal({ showModal, setShowModal, onConfirm }) {

  const { t } = useTranslation();

  return (
    <CommonModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle={t("Warning")}
      modalSpace={true}
      mainContent={
        <>
          <div className='mt-s20 '>
            {t("Please note, editing this request will change the status to 'Saved', and all existing bids will be cancelled.")}
          </div>

          <div className='flex justify-end mt-s20'>
            <CommonButton colorType='danger' onClick={onConfirm} btnLabel={t('Confirm')} width='w-[100px]' />
          </div>
        </>
      }
    />
  )
}
