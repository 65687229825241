/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import useShiftStore, { getShiftTableData, validateDateTime } from '../../../../app/stores/company/shiftStore';
import CommonModal from '../../../../components/modal/CommonModal';
import CommonSelect from '../../../../components/select/CommonSelect';
import CommonDatePicker from '../../../../components/input/CommonDatePicker';
import { formatDateToApiDate, getStringFromDateObject } from '../../../../app/utility/utilityFunctions';
import CommonTimePicker from '../../../../components/input/CommonTimePicker';
import CommonButton from '../../../../components/button/CommonButton';
import CommonCheckbox from '../../../../components/input/CommonCheckbox';
import { useTranslation } from 'react-i18next';
import { kuShiftManagerTableData } from '../../../../app/urls/companyUrl';
import { k_shift_status } from '../../../../app/utility/const';
import MultipleSelectCombo from '../../../../components/select/MultipleSelectCombo';
import CommonSingleSelect from '../../../../components/select/CommonSingleSelect';

export default function ShiftManagerFilter({ showModal, setShowModal }) {

  const { resetFilterFormCopy, filter_form, changeFilterForm, filterShiftCarList, resetFilterForm, updateFilterForm, filter_form_copy, updateFilterFormCopy, filterShiftDriverList, setApiUrl } = useShiftStore();

  const divRef = useRef(null);

  const { t } = useTranslation();

  const [shift_status, setShiftStatus] = useState([
    { id: k_shift_status.upcoming, title: t('Upcoming') },
    { id: k_shift_status.init, title: t('Not Started') },
    { id: k_shift_status.ongoing, title: t('Ongoing') },
    { id: k_shift_status.complete, title: t('Completed') },
  ]);

  useEffect(() => { updateFilterForm(filter_form_copy) }, [showModal]);

  useEffect(() => { if (divRef.current) divRef.current.focus() }, []);

  const driverIds = filter_form?.drivers?.map(item => {
    return item?.id;
  });

  const carIds = filter_form?.cars?.map(item => {
    return item?.id;
  });

  const driversArray = filterShiftDriverList?.filter(item => !driverIds?.includes(item?.value)).map(item => {return {title: item?.title, id: item?.value}});

  const carsArray = filterShiftCarList?.filter(item => !carIds?.includes(item?.value)).map(item => {return {title: item?.title, id: item?.value}});

  return (
    <CommonModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalSpace={true}
      modalTitle={
        <div className='flex items-baseline'>
          <div>{t('Filter')}</div>

          <div className='pl-4 text-base cursor-pointer select-none drop-shadow-sm text-cRed' onClick={async () => {
            await resetFilterFormCopy();
            await resetFilterForm();
            setApiUrl(kuShiftManagerTableData);
            getShiftTableData({
              filter: {
                start_date: "",
                end_date: "",
                start_time: "",
                end_time: "",
                plate_number: "",
                driver_id: "",
                is_maintenance: null,
                status: "",
                cars: [],
                drivers: [],
              }
            });
            setShowModal(false);
          }}>
            {t("Clear")}
          </div>

        </div>
      }
      mainContent={
        <>
          <form className='mt-6' onSubmit={async (e) => {
            e.preventDefault();
            let x = true;
            x = await validateDateTime(filter_form?.start_date, filter_form?.start_time, filter_form?.end_date, filter_form?.end_time);
            if (!x) return;
            setApiUrl(kuShiftManagerTableData);
            await getShiftTableData({
              filter: {
                start_date: (filter_form?.start_time || filter_form?.end_time) && !filter_form?.start_date && !filter_form?.end_date ? formatDateToApiDate(new Date()) : filter_form?.start_date,
                end_date: (filter_form?.start_time || filter_form?.end_time) && !filter_form?.start_date && !filter_form?.end_date ? formatDateToApiDate(new Date()) : filter_form?.end_date,
                start_time: filter_form?.start_time,
                end_time: filter_form?.end_time,
                plate_number: filter_form?.cars?.map(item => {return item?.id}),
                driver_id: filter_form?.drivers?.map(item => {return item?.id}),
                is_maintenance: filter_form?.is_maintenance,
                status: filter_form?.status ? [filter_form?.status] : [],
              }
            });
            updateFilterFormCopy(filter_form);
            setShowModal(false);
          }}>

            <div tabIndex="0" ref={divRef} className='pt-0' ></div>

            <div className="mt-[26px] grid grid-cols-2 items-center gap-7">
              <CommonDatePicker
                value={filter_form?.start_date}
                onChange={(date) => {
                  if(!date) {
                    changeFilterForm('start_date', "");
                    return;
                  }
                  else if (new Date(date) > new Date(filter_form?.end_date)) {
                    changeFilterForm('start_date', getStringFromDateObject(date))
                    changeFilterForm('end_date', "")
                    return;
                  } else {
                    changeFilterForm('start_date', getStringFromDateObject(date))
                  }
                }}
                label={t('Start Date')}
                showClear={true}
              />

              <CommonDatePicker
                startDate={filter_form?.start_date}
                value={filter_form?.end_date}
                onChange={(date) => {
                  if(!date) {
                    changeFilterForm('end_date', "");
                    return;
                  }
                  changeFilterForm('end_date', getStringFromDateObject(date))
                }}
                label={t('End Date')}
                showClear={true}
              />
            </div>

            <div className="mt-[26px] grid grid-cols-2 items-center gap-7">
              <CommonTimePicker
                showExtendedTimeUi={false}
                label={t('Start Time')}
                init_time={filter_form?.start_time}
                onChange={(time) => changeFilterForm('start_time', time)}
                showClear={true}
                handleClear={() => changeFilterForm('start_time', null)}
              />
              <CommonTimePicker
                showExtendedTimeUi={false}
                label={t('End Time')}
                init_time={filter_form?.end_time}
                onChange={(time) => changeFilterForm('end_time', time)}
                showClear={true}
                handleClear={() => changeFilterForm('end_time', null)}
              />
            </div>

            <div className="mt-[14px] items-center gap-7">
              <MultipleSelectCombo 
                disabled={filter_form?.is_maintenance}
                dataArray={carsArray}
                onChange={(value) => changeFilterForm('cars', value)}
                selectedId={filter_form?.cars}
                label={t('Select Car/License Plate')}
                noDataText='No cars available'
              />
            </div>

            <div className="mt-[26px] items-center gap-7">
              <MultipleSelectCombo 
                disabled={filter_form?.is_maintenance}
                dataArray={driversArray}
                onChange={(value) => changeFilterForm('drivers', value)}
                selectedId={filter_form?.drivers}
                label={t('Select Driver')}
                noDataText='No drivers available'
              />
            </div>

            <div className="mt-[26px] grid grid-cols-2 items-center gap-7">
              <CommonSingleSelect
                disabled={filter_form?.is_maintenance}
                label={t('Shift Status')}
                placeholder={t('Shift Status')}
                dataArray={shift_status} 
                has_subtitle={false} 
                onChange={(value) => {
                  changeFilterForm('status', value);
                }}
                value={filter_form?.status}
                isCapitalize={true}
                 />

              <div className="mt-5">
                <CommonCheckbox
                  label={t('in maintenance')}
                  checked={filter_form?.is_maintenance ? true : false}
                  onChange={() => {
                    changeFilterForm('is_maintenance', filter_form?.is_maintenance ? 0 : 1);
                    changeFilterForm('status', "");
                    changeFilterForm('driver_id', "");
                  }} />
              </div>

            </div>


            <div className='flex flex-row items-center justify-end w-full mt-s20'>
              <CommonButton btnLabel={t('Apply Filter')} type='submit' />
            </div>

          </form>
        </>
      }
    />
  )
}
